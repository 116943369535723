import { Button } from "@mui/material";
import useAuthentication from "authentication/useAuthentication";
import { UserRoles } from "Models/User";
import { Link as RouterLink, useMatch } from "react-router-dom";

export default function NavigationAdmin() {
    const { hasRole } = useAuthentication();

    const isAdminRoute = useMatch("/administration/*");
    const isFirmenverwaltung = useMatch("/administration/firmen");
    const isArtikelverwaltung = useMatch("/administration/artikel");
    const isAdminUserMgmt = useMatch("/administration/users");

    return (
        <>
            {isAdminRoute && hasRole(UserRoles.ADMINISTRATOR, UserRoles.KOORDINATOR) && (
                <>
                    <Button
                        component={RouterLink}
                        to="/administration/artikel"
                        className={isArtikelverwaltung ? "activeLink" : ""}
                        key="artikelverwaltung"
                        sx={{ my: 2, color: "white", display: "block" }}
                    >
                        Artikelverwaltung
                    </Button>
                    {hasRole(UserRoles.ADMINISTRATOR) && (
                        <Button component={RouterLink} to="/administration/users" className={isAdminUserMgmt ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                            Benutzerverwaltung
                        </Button>
                    )}
                    <Button component={RouterLink} to="/administration/firmen" className={isFirmenverwaltung ? "activeLink" : ""} sx={{ my: 2, color: "white", display: "block" }}>
                        Firmenverwaltung
                    </Button>
                </>
            )}
        </>
    );
}
